import CPVScanner from '@cpv/components/CPVScanner';
import logo from '../../assets/cropped-den_eik_transparent.png'

export const CPVLandingPage = (): JSX.Element => (
    <div>
        <div className="p-2 d-flex background">
            <img className="logo-width" src={logo} alt="logo"/>
        </div>
        <div className="bx--grid cpv-landing-page__grid">
            <div className="bx--row cpv-landing-page__banner">
                <div className="bx--col-lg-16">
                    <h3 className="cpv-landing-page__heading">
                        Welkom!
                        <br></br>
                        <strong>Gelieve je Covid Safe Ticket te scannen</strong>
                    </h3>
                </div>
            </div>
            <div className="bx--row cpv-landing-page__scanner">
                <div className="bx--col-lg-16 max-width-514">
                    <CPVScanner/>
                </div>
            </div>
        </div>
    </div>
);

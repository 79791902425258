import {useEffect, useState} from 'react';

import {HCERT} from '@cpv/lib/hcert';
import {parseHCERT} from '@cpv/lib/hcert-parser';
import {HCERTStatus, validateHCERT} from '@cpv/lib/hcert-verification';
// import { getCountry } from '@cpv/lib/valuesets/country-2-codes';

type Props = {
  qrData: string;
  onHCERTStatus: (status: HCERTStatus) => void;
};

export const CPVQrDataTable = ({ qrData, onHCERTStatus }: Props): JSX.Element => {
  const [hcert, setHCERT] = useState<HCERT | null>(null);

  useEffect(() => {
    async function getHCERT() {
      let status = HCERTStatus.Error;

      try {
        const hcert = await parseHCERT(qrData);
        setHCERT(hcert);
        status = validateHCERT(hcert);
      } catch (e) {
        console.error(e);
      }

      onHCERTStatus(status);
    }

    getHCERT();
  }, [qrData]);

  if (hcert === null) {
    return <></>;
  }
  return <></>;
};
